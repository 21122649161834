(<template>
  <div class="one-translation-project">
    <div class="one-translation-project__container">
      <h1 class="one-translation-project__title">{{ $pgettext('translation', 'Thank you for your order') }}</h1>
      <p class="one-translation-project__description">
        {{ $pgettext('translation', 'We have received your order.') }}
        <br />
      </p>
      <summary-info :summary-list="summaryList"
                    class="one-translation-project__summary-info" />
      <booking-buttons class="one-translation-project__btns" />
      <p class="one-translation-project__description"
         v-html="description"></p>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import Vue from 'vue';
  import getProductConfig from '@/modules/productConfig';
  import SummaryInfo from '@/components/booking_components/SummaryInfo';
  import BookingButtons from '@/components/booking_components/BookingButtons';

  export default {
    components: {
      'summary-info': SummaryInfo,
      'booking-buttons': BookingButtons
    },
    computed: {
      ...mapState('OneProjectStore', {
        project: (state) => state.project
      }),
      description() {
        const template = this.$pgettext('translation', 'If you have any questions or concerns, please send an email to <a class="sk-link sk-link--default" href="mailto:%{email}">%{email}</a>, or call our Translations team at %{domain} on <a href="tel:%{tel}" class="js-copy-to-clipboard">%{tel}</a>.');
        return this.$gettextInterpolate(template, {email: getProductConfig(Vue.prototype.$gettext, 'salita', 'domainData', 'supportTranslationEmail'), tel: getProductConfig(Vue.prototype.$gettext, 'salita', 'domainData', 'supportPhone'), domain: getProductConfig(Vue.prototype.$gettext, 'domainData', 'platformName')});
      },
      langFrom() { return this.project.sourceLanguageId; },
      targetLanguageNames() {
        return this.project.targetLanguages?.map((langId) => {
          return this.$getLangName(langId);
        });
      },
      deadline() { return this.project.externalDeadline ? this.$moment(this.project.externalDeadline).format('DD.MM.YYYY HH:mm') : ''; },
      departmentName() { return this.project.department ? this.project.department.name : ''; },
      translatorInformation() { return this.project.instructionsFromDemander; },
      caseNumber() { return this.project.caseNumber; },
      bookingRef() { return this.project.bookingReference; },
      paymentBookingRef() { return this.project.paymentBookingReference; },
      owner() { return this.project.owner; },
      enterprise() { return this.project.enterprise; },
      attachments() { return this.project.initialAttachmentsNames || []; },
      summaryList() {
        return [{
          lines: [
            {text: this.$pgettext('translation', 'Owner'), value: this.owner?.name},
            {text: this.$pgettext('translation', 'Email'), value: this.owner?.email},
            {text: this.$pgettext('translation', 'Phone'), value: this.owner?.phone},
            {text: this.$pgettext('translation', 'Org. number'), value: this.enterprise?.orgNumber},
            {text: this.$pgettext('translation', 'Department'), value: this.departmentName},
          ]
        }, {
          lines: [
            {text: this.$pgettext('translation', 'Language from'), value: this.$getLangName(this.langFrom)},
            {text: this.$pgettext('translation', 'Target languages'), value: this.targetLanguageNames},
            {text: this.$pgettext('translation', 'Deadline'), value: this.deadline},
            {text: this.$pgettext('translation', 'Information for our translation team'), value: this.translatorInformation},
            {text: this.$pgettext('translation', 'Uploaded file(s)'), value: this.attachments}
          ]
        }, {
          lines: [
            {text: this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$pgettext('translation', 'Booking ref.'), value: this.bookingRef},
            {text: this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$pgettext('translation', 'Attestant BID'), value: this.paymentBookingRef},
            {text: this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$pgettext('translation', 'Case number'), value: this.caseNumber}
          ]
        }];
      }
    },
    beforeMount() {
      if (!this.project) { this.$router.replace(this.$makeRoute({name: 'BuyerHome'})); }
    }
  };
</script>

<style scoped>
  .one-translation-project {
    padding: 30px 40px;
    background-color: #f2f2f2;
  }

  .one-translation-project__container {
    width: 100%;
    max-width: 690px;
    margin: auto;
    padding: 40px 40px 30px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .one-translation-project__title {
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: normal;
    font-size: 22px;
  }

  .one-translation-project__description,
  .one-translation-project__btns {
    margin-top: 10px;
  }

  .one-translation-project__summary-info {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.79;
  }

  @media (max-width: 767px) {
    .one-translation-project {
      padding: 30px 15px;
    }

    .one-translation-project__container {
      padding: 20px 10px;
    }
  }
</style>
