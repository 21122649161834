(<template>
  <landing-wrapper class="success-info">
    <one-translation />
  </landing-wrapper>
</template>)

<script>
  import LandingWrapper from '@/components/landing/LandingWrapper';
  import bOneTranslation from '@/pages/BuyerPages/project_pages/b_OneTranslationProject';

  export default {
    components: {
      'landing-wrapper': LandingWrapper,
      'one-translation': bOneTranslation
    }
  };
</script>

<style scoped src="@/assets/tikktalk/css/open_booking_success_info.css"></style>
